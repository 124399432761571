import React from 'react';
import * as Survey from 'survey-react';
import { connect } from 'react-redux';
import 'styles/surveyResponses.scss';

import { Alert } from 'reactstrap';
import MaintenancePage from '../../components/MaintenancePage';

import {
	SURVEY_QUESTION_TYPES_VALUES,
	OPTION_TYPES,
	DEFAULT_LANGUAGE_CODE,
	SURVEY_RESPONSE_ITERATION_DELAY,
	SURVEY_RESPONSE_SOURCE,
	SURVEY_RESPONSE_TYPE,
} from '../../constants/config';

import {
	SURVEY_BODY_CONTAINER_BACKGROUND_COLOUR,
	SURVEY_HEADER_BACKGROUND_COLOUR,
	SURVEY_HEADER_COLOUR,
	SURVEY_MAIN_COLOUR,
	SURVEY_MAIN_HOVER_COLOUR,
	SURVEY_TEXT_COLOUR,
} from '../../constants/customUIConfig';

import {
	authenticateSurveyUser,
	changeSurveyResponsesLanguage,
	saveSurveyResponse,
	setSurveyResponseMessages,
	setSurveyResponseUserId,
	setCurrentLanguageFetchStatusNone,
	startSurveyAfterWelcomePage,
	applyTranslationsForAlertMessages,
	setSurveyResponseClient,
	saveSurveyResponseBackground,
	setIdentifier,
} from '../../actions/survey/responses/ActtionsSurveyResponses';

import AppUtils from '../../util/util';
import IntlMessages from '../../util/IntlMessages';
import StorageHandler from '../../util/storageHandler';
import { getTranslationStringList } from '../../actions/adminSettings/translations/strings/actionsTranslationStrings';
import Button from '@material-ui/core/Button';
import CircularProgress from '../../components/CircularProgress';
import { HTTP_REQUEST_STATUS, MOBILE_VIEW } from '../../constants/constants';
import commonStyles from '../../util/commonStyle';
import { getAllSurveyTranslations } from '../../actions/adminSettings/translations/modules/ActionsTranslationsModules';
import InternetError from './NetworkIssue';

const querySearch = require('stringquery');
const crc32 = require('crc/cjs/crc32').default

class WoVoSurveyResponse extends React.Component {
	constructor() {
		super();

		this.isSaveInProgress = false;
		this.isQueryInProgress = false;
		this.isBackgroundSaveInProgress = false;
		this.currentURL = '';

		this.state = {
			clientId: -1,
			surveyId: '',
			key: AppUtils.getUUID(),
			timerCount: 0,
			currentTimerCount: 0,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	/** mount event **/
	componentDidMount() {
		try {
			this.applyBackground();

			if (this.props.fetchStatus) {
				this.initProcess();
			}

			let defaultThemeColors = Survey.StylesManager.ThemeColors['default'];
			defaultThemeColors['$main-color'] = SURVEY_MAIN_COLOUR;
			defaultThemeColors['$main-hover-color'] = SURVEY_MAIN_HOVER_COLOUR;
			defaultThemeColors['$text-color'] = SURVEY_TEXT_COLOUR;
			defaultThemeColors['$header-color'] = SURVEY_HEADER_COLOUR;
			defaultThemeColors[
				'$header-background-color'
			] = SURVEY_HEADER_BACKGROUND_COLOUR;
			defaultThemeColors[
				'$body-container-background-color'
			] = SURVEY_BODY_CONTAINER_BACKGROUND_COLOUR;
			Survey.StylesManager.applyTheme();
		} catch (ex) {
			console.log('componentDidMount : error : ', ex);
		}
	}

	applyBackground = () => {
		try {
			let customCssStyle = document.createElement('style');
			customCssStyle.type = 'text/css';
			customCssStyle.innerHTML = '.app-main-content {background-color: white;}';
			document.getElementsByTagName('head')[0].appendChild(customCssStyle);
		} catch (ex) {
			console.error('applyBackground : error : ', ex);
		}
	};

	processPreviousSavedResponses = () => {
		try {
			const { ReducerSurveyResponses } = this.props;
			let prevResponses = StorageHandler.getSurveyResponses();

			if (
				AppUtils.getJSONObjectLength(prevResponses) === 0 ||
				ReducerSurveyResponses.responseBackgroundSaveStatus !==
					HTTP_REQUEST_STATUS.NOT_STARTED ||
				this.isBackgroundSaveInProgress
			) {
				return;
			}

			let that = this;
			this.isBackgroundSaveInProgress = true;

			for (let item in prevResponses) {
				if (item != ReducerSurveyResponses.currentResponses) {
					window.setTimeout(function() {
						that.props.saveSurveyResponseBackground(prevResponses[item]);
						that.isBackgroundSaveInProgress = true;
					}, 5000);
					break;
				}
			}
		} catch (ex) {
			console.error('processPreviousSavedResponses : error : ', ex);
		}
	};

	/** unmount event **/
	componentWillUnmount() {}

	componentDidUpdate(prevProps, prevState) {
		const { responseType } = this.state;
		const {
			isCurrentLanguageFetched,
			currentLanguage,
			remoteTranslations,
			isOneResponsePerDevice,
			currentEmployeeId,
			ReducerSurveyResponses,
			identifier,
		} = this.props;

		if (
			ReducerSurveyResponses.responseSaveStatus === HTTP_REQUEST_STATUS.FAIL &&
			ReducerSurveyResponses.currentResponseKey !== '' &&
			!this.isQueryInProgress &&
			ReducerSurveyResponses.isAutoRetryEnabled
		) {
			let that = this;

			let currentMillis =
				SURVEY_RESPONSE_ITERATION_DELAY * ReducerSurveyResponses.attemptCount;

			window.setTimeout(function() {
				that.isQueryInProgress = false;
				that.props.saveSurveyResponse(ReducerSurveyResponses.currentResponses);
			}, currentMillis);
		}

		if (
			isCurrentLanguageFetched &&
			remoteTranslations &&
			Object.keys(remoteTranslations).length !== 0
		) {
			let storageKey = this.getStorageKey();

			if (
				currentEmployeeId === null &&
				identifier === null &&
				((isOneResponsePerDevice && responseType === '') ||
					(responseType !== '' &&
						responseType == SURVEY_RESPONSE_TYPE.SINGLE)) &&
				StorageHandler.isSurveyCompletedForTheDevice(storageKey)
			) {
				this.props.setSurveyResponseMessages(
					'DIC_SURVEY_RESPONSE_ERROR_ALREADY_SUBMITTED'
				);
			}

			this.applyTranslations(currentLanguage);
			this.props.setCurrentLanguageFetchStatusNone();
		}
		if (
			prevProps.currentLanguage !== '' &&
			prevProps.currentLanguage !== currentLanguage &&
			remoteTranslations &&
			Object.keys(remoteTranslations).length !== 0
		) {
			this.applyTranslations(currentLanguage);
		}

		this.processPreviousSavedResponses();
	}

	updateCurrentTimerCount = () => {
		try {
			const { timerCount } = this.state;

			let that = this;

			let threashold = timerCount - new Date().getTime();

			if (threashold < 0) {
				threashold = 0;
			}

			this.setState({
				currentTimerCount: parseInt(threashold / 1000),
			});

			if (threashold > 0) {
				window.setTimeout(function() {
					that.updateCurrentTimerCount();
				}, 1000);
			}
		} catch (ex) {
			console.error('updateCurrentTimerCount : error : ', ex);
		}
	};

	getStorageKey = () => {
		try {
			const { surveyId, clientId } = this.state;
			let storageKey = surveyId;

			if (clientId !== -1) {
				storageKey += '_' + clientId;
			}

			return storageKey;
		} catch (ex) {
			console.log('getStorageKey : ', ex);
		}
		return '';
	};

	initProcess = () => {
		/** This method will get the translation object for each language */

		this.props.getTranslationStringList({ is_translated: true });
		// this.props.getTranslationList();

		let searchParamsVal = AppUtils.getSurveyResponseParams();

		const urlSearchParams = new URLSearchParams(searchParamsVal);
		const base64EncodedParams = urlSearchParams.get('q');
		const crc = urlSearchParams.get('c');

		/**Decode the query params coming in the url */
		if (base64EncodedParams) {
			const decodedParams = base64DecodedUrlparams(base64EncodedParams);
			searchParamsVal = decodedParams;

			/** if crc present, validate the decoded params */
			if (crc && crc !== crc32(decodedParams).toString()) {
				searchParamsVal = ''
			}
		}

		if (searchParamsVal !== '') {
			const searchParams = querySearch(searchParamsVal);

			if (this.currentURL === '') {
				let currentURL = '';

				for (let item in searchParams) {
					if (currentURL !== '') {
						currentURL += '&';
					}
					currentURL += item + '=' + searchParams[item];
				}
				this.currentURL = currentURL;
				AppUtils.redirectToValidDomain('/survey_response/?' + this.currentURL);
			}

			if (searchParams?.id ?? '') {
				const responseSource =
					searchParams?.response_source || SURVEY_RESPONSE_SOURCE.URL;
				const responseType =
					searchParams?.response_type || SURVEY_RESPONSE_TYPE.MULTIPLE;
				const employeeId =
					searchParams?.employeeId || searchParams.employeeid || null;
				const isSurveyCompleted =
					employeeId !== null &&
					StorageHandler.isSurveyCompleted(searchParams.id, employeeId);
				const clientId =
					searchParams?.companyid || searchParams.companyId || -1;
				const surveyId = searchParams?.id || '';
				const identifier = searchParams?.identifier || null;

				let isUrlInvalid = false;

				if (surveyId === '' || isNaN(clientId) || isNaN(employeeId)) {
					isUrlInvalid = true;
				}

				if (isUrlInvalid) {
					this.props.setSurveyResponseMessages(
						'DIC_SURVEY_RESPONSE_ERROR_INVALID_SURVEY_URL'
					);
					return;
				}
				if (isSurveyCompleted) {
					this.props.setSurveyResponseMessages(
						'DIC_SURVEY_RESPONSE_ERROR_ALREADY_SUBMITTED'
					);
				}

				this.props.setSurveyResponseClient(clientId);

				this.setState({
					clientId: clientId,
					surveyId: surveyId,
					responseType: responseType,
					responseSource: responseSource,
				});

				if (employeeId !== null) this.props.setSurveyResponseUserId(employeeId);

				if (identifier !== null) this.props.setIdentifier(identifier);

				let params = {};

				params['id'] = surveyId;
				params['client_id'] = clientId;

				if (employeeId !== null) params['empId'] = employeeId;
				if (identifier !== null) params['identifier'] = identifier;

				this.props.authenticateSurveyUser(params);
			}
		} else {
			this.props.setSurveyResponseMessages(
				'DIC_SURVEY_RESPONSE_ERROR_INVALID_SURVEY'
			);
		}
	};

	/** language dropdown change event **/
	handleChange(event) {
		const currentLanguage = event.target.value;

		this.props.changeSurveyResponsesLanguage(currentLanguage);
	}

	/** Survey component's on complete event **/
	onComplete = (sender) => {
		const {
			surveyId,
			currentLanguage,
			currentEmployeeId,
			ReducerSurveyResponses,
			identifier,
		} = this.props;
		const { clientId, key, responseType, responseSource } = this.state;

		if (this.isSaveInProgress) {
			console.log(
				'onComplete : already query in progress and returning : ',
				this.isSaveInProgress
			);
			return;
		}

		this.isSaveInProgress = true;

		let surveyResponse = {
			survey_id: surveyId,
			responses: this.modifySurveyResults(sender),
			language_code: currentLanguage,
			client_id: clientId,
			empId: currentEmployeeId,
			key: key,
			request_id: ReducerSurveyResponses.requestId,
			current_url: this.currentURL,
			response_source: responseSource,
			response_type: responseType,
			identifier: identifier,
		};

		if (this.currentURL !== '') {
			this.props.saveSurveyResponse(surveyResponse);
		}
	};

	handleRetryButtonClick = () => {
		try {
			const { ReducerSurveyResponses } = this.props;
			this.props.saveSurveyResponse(ReducerSurveyResponses.currentResponses);
		} catch (ex) {
			console.error('handleRetryButtonClick : error : ', ex);
		}
	};

	getQuestionTypeFromData = (key, questionType) => {
		let result = questionType;

		try {
			const { surveys } = this.props;

			for (let i = 0; i < surveys.pages.length; i++) {
				for (let j = 0; j < surveys.pages[i].questions.length; j++) {
					if (surveys.pages[i].questions[j]['name'] === key) {
						result = surveys.pages[i].questions[j]['question_type'];
						break;
					}
				}
			}
		} catch (ex) {
			console.error('getQuestionTypeFromData : error : ', ex);
		}

		return result;
	};

	/** modifying the survey response based on the api request **/
	modifySurveyResults = (survey) => {
		const { surveys, currentLanguage } = this.props;
		let resultData = [];

		for (let key in survey.data) {
			let question = survey.getQuestionByValueName(key);
			if (question) {
				let question_type = Object.keys(OPTION_TYPES).find(
					(key) => OPTION_TYPES[key] === question.getType()
				);
				question_type = this.getQuestionTypeFromData(key, question_type);
				const item = {
					question_id: key,
					questoin_type: question_type,
				};

				if (
					question_type == SURVEY_QUESTION_TYPES_VALUES.LINEAR_RATING_SCALE ||
					question_type == SURVEY_QUESTION_TYPES_VALUES.TEXT_LONG ||
					question_type == SURVEY_QUESTION_TYPES_VALUES.TEXT_SHORT ||
					question_type == SURVEY_QUESTION_TYPES_VALUES.EMAIL
				) {
					item.option_id = [];
					item.free_text = question.value;
				} else if (question_type === SURVEY_QUESTION_TYPES_VALUES.ENPS) {
					const page = surveys.pages.find(
						(p) =>
							p.questions[0].question_type === SURVEY_QUESTION_TYPES_VALUES.ENPS
					);
					if (page && page.questions && page.questions.length) {
						const selectedChoice = page.questions[0].choices.find(
							(ch) => ch.text[currentLanguage] === question.value
						);
						item.option_id = [selectedChoice.value || question.value];
					} else {
						item.option_id = [question.value];
					}
				} else {
					item.option_id = this.prepareOptionValues(question.value);
					item.free_text = '';
				}
				resultData.push(item);
			}
		}
		return resultData;
	};

	prepareOptionValues = (optionValues) => {
		let questionOptionValues = [];
		if (typeof optionValues === 'string') {
			questionOptionValues.push(optionValues);
		} else {
			questionOptionValues = [...optionValues];
		}
		return questionOptionValues;
	};

	applyTranslations = (currentLanguage) => {
		const { translationStrings, alertMessagesTranslations } = this.props;

		let alertMessageTranslations = {};

		try {
			let result = this.getTranslationStringsForCurrentLanguage();

			if (typeof result !== 'undefined') {
				let localeTranslations = {};
				for (let key in translationStrings) {
					if (translationStrings[key] in result) {
						localeTranslations[key] = result[translationStrings[key]];
					}
				}
				if (Object.keys(localeTranslations).length > 0) {
					Survey.surveyLocalization.locales[
						currentLanguage
					] = localeTranslations;
				}
				for (let key in alertMessagesTranslations) {
					if (key in result) {
						alertMessageTranslations[key] = result[key];
					} else {
						alertMessageTranslations[key] = alertMessagesTranslations[key];
					}
				}
			}
		} catch (ex) {
			console.error('applyTranslations : Error' + ex);
		}

		this.props.applyTranslationsForAlertMessages(alertMessageTranslations);
	};

	getTranslationStringsForCurrentLanguage = () => {
		const {
			currentLanguage,
			remoteTranslations,
			ReducerSurveyResponses,
		} = this.props;

		let result = [];

		try {
			const selectedLanguage = ReducerSurveyResponses.supportingLanguages.find(
				(lang) => {
					if (
						typeof lang['language_code'] !== 'undefined' &&
						lang['language_code'] === currentLanguage
					) {
						return lang;
					}
				}
			);

			if (selectedLanguage && selectedLanguage['is_translated']) {
				result = AppUtils.getTranslationStringsForLanguage(
					remoteTranslations,
					currentLanguage
				);
			} else {
				result = AppUtils.getTranslationStringsForLanguage(
					remoteTranslations,
					DEFAULT_LANGUAGE_CODE
				);
			}
		} catch (ex) {
			console.error('getTranslationStringsForCurrentLanguage : error : ', ex);
		}
		return result;
	};

	renderWelcomePage = () => {
		const {
			openMessage,
			currentLanguage,
			surveys,
			alertMessagesTranslations,
		} = this.props;

		let isOpeningMessagePresent = Object.keys(openMessage).length !== 0;

		return (
			isOpeningMessagePresent && (
				<div className="container overflow-auto">
					<div className="ml-4">
						<img
							src={require('assets/images/wovo.svg')}
							alt="Wovo"
							title="Wovo"
							width={surveys.logoWidth}
							height={surveys.logoHeight}
						/>
						<h2
							className="ml-2 mt-2 pt-4"
							style={{ display: 'inline-block', color: '#42a1ff' }}
						>
							<b>{surveys.title[currentLanguage]}</b>
						</h2>
					</div>
					<div className="row text-center mt-4 align-items-center">
						<div className="col">
							<h1>
								<span
									dangerouslySetInnerHTML={{
										__html: openMessage['translations'][currentLanguage],
									}}
								></span>
							</h1>

							{openMessage['url'] !== '' && (
								<h1>
									<a href={openMessage['url']} target="_blank">
										{openMessage['url']}
									</a>
								</h1>
							)}
						</div>
					</div>
					<div className="row float-right mr-4 mt-4">
						<Button
							onClick={() => this.props.startSurveyAfterWelcomePage()}
							variant="contained"
							style={{ backgroundColor: '#4caf50', color: 'white' }}
						>
							{
								alertMessagesTranslations[
									'DIC_SURVEY_RESPONSE_START_SURVEY_BUTTON'
								]
							}
						</Button>
					</div>
				</div>
			)
		);
	};

	renderClosingPage = () => {
		try {
			let { closeMessage, currentLanguage } = this.props;
			let isClosingMessagePresent = Object.keys(closeMessage).length !== 0;

			if (isClosingMessagePresent) {
				if (
					typeof Survey.surveyLocalization.locales[currentLanguage] !=
					'undefined'
				) {
					let myLocalTranslations =
						Survey.surveyLocalization.locales[currentLanguage];
					myLocalTranslations.completingSurvey =
						closeMessage['translations'][currentLanguage];

					if (
						closeMessage['translations'][currentLanguage].indexOf('DIC_') != -1
					) {
						let result = this.getTranslationStringsForCurrentLanguage();

						if (
							typeof result[closeMessage['translations'][currentLanguage]] !=
							'undefined'
						) {
							myLocalTranslations.completingSurvey =
								result[closeMessage['translations'][currentLanguage]];
						}
					}
				}
			}
		} catch (ex) {
			console.error('renderClosingPage : error : ', ex);
		}
	};

	handleQuestionAnswerChange = (content, options) => {
		try {
			const { ReducerSurveyResponses, identifier } = this.props;
			const { surveyId, clientId } = this.state;

			let result = {
				surveyId: surveyId,
				clientId: clientId,
				userId:
					ReducerSurveyResponses.currentEmployeeId !== null
						? ReducerSurveyResponses.currentEmployeeId
						: identifier !== null
						? identifier
						: null,
				data: content.data,
			};
			StorageHandler.saveSurveyPartialResponse(result);
		} catch (ex) {
			console.error('handleQuestionAnswerChange : error : ', ex);
		}
	};

	renderAutoRetryContainer = () => {
		const { ReducerSurveyResponses } = this.props;
		//ReducerSurveyResponses.responseSaveStatus

		let currentMillis = parseInt(
			(SURVEY_RESPONSE_ITERATION_DELAY * ReducerSurveyResponses.attemptCount) /
				1000
		);

		return (
			<main className="app-main-content-wrapper">
				<div className="app-main-content">
					<div className="row col">
						<div className="col-md-12 col-lg-12 col-12 pt-5 mt-5">
							<div className="d-flex justify-content-center pt-5 mt-5">
								<InternetError />
							</div>
						</div>

						{ReducerSurveyResponses.responseSaveStatus !==
							HTTP_REQUEST_STATUS.IN_PROGRESS &&
							ReducerSurveyResponses.isRetryButtonVisible && (
								<div className="col-md-12 col-lg-12 col-12">
									<div className="d-flex justify-content-center">
										<Button
											variant="contained"
											size="large"
											disabled={
												ReducerSurveyResponses.responseSaveStatus ===
												HTTP_REQUEST_STATUS.IN_PROGRESS
											}
											style={commonStyles.commonNewButton}
											onClick={this.handleRetryButtonClick}
										>
											{<IntlMessages id="DIC_BUTTON_LABEL_RETRY" />}
										</Button>
									</div>
								</div>
							)}

						{ReducerSurveyResponses.responseSaveStatus ===
							HTTP_REQUEST_STATUS.IN_PROGRESS && (
							<div className="col-md-12 col-lg-12 col-12">
								<div className="d-flex justify-content-center">
									<CircularProgress color="secondary" size={24} />
								</div>
							</div>
						)}
					</div>
				</div>
			</main>
		);
	};

	renderFullLoader = () => {
		return (
			<div
				className="full-screen-loader mt-4 mb-4 "
				style={{ marginLeft: '48%' }}
			>
				<CircularProgress color="secondary" size={24} />
			</div>
		);
	};

	renderSurveyErrorAlreadyCompleted = () => {
		const { alertMessagesTranslations } = this.props;

		return (
			<div className="container pt-4 mt-4 ">
				<Alert className="shadow-lg" color="info">
					{
						alertMessagesTranslations[
							'DIC_SURVEY_RESPONSE_ERROR_ALREADY_SUBMITTED'
						]
					}
				</Alert>
			</div>
		);
	};

	renderErrorMessage = () => {
		const {
			isErrorMessage,
			alertMessage,
			alertMessagesTranslations,
		} = this.props;

		return (
			isErrorMessage && (
				<div className="container pt-4 mt-4 ">
					<Alert className="shadow-lg" color="secondary">
						{alertMessage in alertMessagesTranslations ? (
							alertMessagesTranslations[alertMessage]
						) : alertMessage.indexOf('DIC_') != -1 ? (
							<IntlMessages id={alertMessage} />
						) : (
							alertMessage
						)}
					</Alert>
				</div>
			)
		);
	};

	onTextMarkdown = (survey, options) => {
		try {
			options.html = options.text;
		} catch (ex) {
			console.error('onTextMarkdown : error : ', ex);
		}
	};

	renderMainContainer = () => {
		const {
			surveys,
			isErrorMessage,
			currentLanguage,
			startSurvey,
			ReducerSurveyResponses,
		} = this.props;

		let options = [];

		if (typeof ReducerSurveyResponses.supportingLanguages != 'undefined') {
			options = ReducerSurveyResponses.supportingLanguages.map((language) => (
				<option key={language.id} value={language.language_code}>
					{language.name}
				</option>
			));
		}
		return (
			<>
				{!isErrorMessage && surveys && surveys.title && (
					<>
						<div className="container">
							<div className="row col">
								<select
									className="languageDropDown MuiInputBase-input"
									onChange={this.handleChange}
									value={currentLanguage}
								>
									{options}
								</select>
							</div>
						</div>
						{!startSurvey && this.renderWelcomePage()}
						{startSurvey && (
							<div className="container">
								<Survey.Survey
									json={surveys}
									data={ReducerSurveyResponses.prevSavedResponses}
									onComplete={this.onComplete}
									locale={currentLanguage}
									onValueChanged={this.handleQuestionAnswerChange}
									onTextMarkdown={this.onTextMarkdown}
								/>
							</div>
						)}
					</>
				)}

				{this.renderErrorMessage()}
			</>
		);
	};

	renderCompleteMessage = () => {
		const { ReducerSurveyResponses } = this.props;
		const { closeMessage, currentLanguage } = ReducerSurveyResponses;

		let isCustomCloseMessage =
			typeof closeMessage != 'undefined' &&
			typeof closeMessage['translations'] != 'undefined' &&
			typeof closeMessage['translations'][currentLanguage] != 'undefined';

		if (isCustomCloseMessage) {
		}

		if (closeMessage['translations'][currentLanguage].indexOf('DIC_') != -1) {
			let result = this.getTranslationStringsForCurrentLanguage();

			if (
				typeof result[closeMessage['translations'][currentLanguage]] !=
				'undefined'
			) {
				closeMessage['translations'][currentLanguage] =
					result[closeMessage['translations'][currentLanguage]];
			}
		}

		return isCustomCloseMessage ? (
			<div className="container overflow-auto">
				<div className="row" style={{ marginTop: '15%' }}>
					<div className="col-12 text-center mt-4 align-items-center">
						<h1>
							<span
								dangerouslySetInnerHTML={{
									__html: closeMessage['translations'][currentLanguage],
								}}
							></span>
						</h1>

						{closeMessage['url'] !== '' && (
							<h1>
								<a href={closeMessage['url']} target="_blank">
									{closeMessage['url']}
								</a>
							</h1>
						)}
					</div>
				</div>
			</div>
		) : (
			<div className="container pt-4 mt-4 ">
				<Alert className="shadow-lg" color="info">
					{ReducerSurveyResponses.alertMessage}
				</Alert>
			</div>
		);
	};

	render() {
		const {
			isSurveyCompleted,
			fetchStatus,
			isTranslationApplied,
			ReducerSurveyResponses,
			enableMaintenanceBanner,
			showBanner,
			enableMaintenancePage,
		} = this.props;

		let isMainContainerVisible = true;

		if (
			ReducerSurveyResponses.isAutoRetryEnabled ||
			ReducerSurveyResponses.isRetryButtonVisible ||
			ReducerSurveyResponses.isResponseSubmitted ||
			fetchStatus ||
			!isTranslationApplied ||
			isSurveyCompleted
		) {
			isMainContainerVisible = false;
		}

		return (
			<main
				className={`app-main-content-wrapper ${
					enableMaintenanceBanner === true &&
					showBanner === true &&
					window.innerWidth > MOBILE_VIEW
						? 'add_banner_to_top'
						: ''
				}`}
			>
				{enableMaintenancePage === true && window.innerWidth > MOBILE_VIEW ? (
					<MaintenancePage />
				) : (
					<div className="app-main-content">
						{ReducerSurveyResponses.isResponseSubmitted &&
							this.renderCompleteMessage()}
						{!fetchStatus &&
							(ReducerSurveyResponses.isAutoRetryEnabled ||
								ReducerSurveyResponses.isRetryButtonVisible) &&
							this.renderAutoRetryContainer()}
						{(fetchStatus || !isTranslationApplied) && this.renderFullLoader()}
						{isSurveyCompleted &&
							!ReducerSurveyResponses.isAutoRetryEnabled &&
							!ReducerSurveyResponses.isRetryButtonVisible &&
							this.renderSurveyErrorAlreadyCompleted()}
						{isMainContainerVisible && this.renderMainContainer()}
					</div>
				)}
			</main>
		);
	}
}

const mapStateToProps = ({
	ReducerSurveyResponses,
	settings,
	ReducerMaintenanceBanner,
}) => {
	const {
		surveys,
		supportingLanguages,
		alertMessage,
		isSuccessMessage,
		isErrorMessage,
		surveyId,
		clientId,
		fetchStatus,
		currentLanguage,
		isSurveyCompleted,
		translationStrings,
		isCurrentLanguageFetched,
		openMessage,
		closeMessage,
		startSurvey,
		isOneResponsePerDevice,
		currentEmployeeId,
		isTranslationApplied,
		alertMessagesTranslations,
		identifier,
	} = ReducerSurveyResponses;

	const { remoteTranslations } = settings;

	const {
		enableBanner,
		showBanner,
		enableMaintenancePage,
	} = ReducerMaintenanceBanner;

	return {
		surveys,
		supportingLanguages,
		alertMessage,
		isSuccessMessage,
		isErrorMessage,
		surveyId,
		clientId,
		fetchStatus,
		currentLanguage,
		isSurveyCompleted,
		translationStrings,
		remoteTranslations,
		isCurrentLanguageFetched,
		openMessage,
		closeMessage,
		startSurvey,
		isOneResponsePerDevice,
		currentEmployeeId,
		isTranslationApplied,
		alertMessagesTranslations,
		ReducerSurveyResponses: ReducerSurveyResponses,
		enableMaintenanceBanner: enableBanner,
		showBanner,
		enableMaintenancePage,
		identifier,
	};
};

/**Need to pass only the Base64 encoded string */
const base64DecodedUrlparams = (encodedString) => {
	let decodedString = '';

	try {
		if (encodedString) {
			return atob(encodedString);
		}
	} catch (err) {
		console.error('base64DecoderUrlparams : error : ', err);
	}
	return decodedString;
};

export default connect(mapStateToProps, {
	saveSurveyResponse,
	authenticateSurveyUser,
	setSurveyResponseMessages,
	changeSurveyResponsesLanguage,
	setSurveyResponseUserId,
	setCurrentLanguageFetchStatusNone,
	getTranslationStringList,
	startSurveyAfterWelcomePage,
	applyTranslationsForAlertMessages,
	setSurveyResponseClient,
	saveSurveyResponseBackground,
	setIdentifier,
})(WoVoSurveyResponse);
