import React from "react";
import Button from "@material-ui/core/Button";
import IntlMessages from "./IntlMessages";
import {
  IconButton,
  Input,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import IntegrationReactSelect from "../components/custom/IntegrationReactSelect";
import Switch from "@material-ui/core/Switch";
import MultiSelectChipSelect from "../components/custom/MultiSelectChipSelect";
import CharacterCounterTextarea from "components/LSInputTextBoxWithCount";
import CharacterCounterTextareaWithoutInfoAndLabel from "components/SendSurveyInputBoxWithoutLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Badge } from "reactstrap";
import LSOverflowMenu from "../components/LSOverflowMenu";
import AppUtils from "./util";
import DatePickers from "../components/custom/DatePickers";
import WoVoDateRangePicker from "../components/wovo/WoVoDateRangePicker";
import LanguageUtils from "./language/languageUtils";
import Chip from "@material-ui/core/Chip";
import { Close, Cancel, PanoramaSharp } from "@material-ui/icons";
import { Multiselect } from "multiselect-react-dropdown";
import MultiSelectChipDropdown from "components/LSMultiSelectDropdown";
import DateTimeWithPopUp from "../components/DateTimePickerWithRadioButton";
import {
  SURVEY_QUESTION_EMOJI_TYPES,
  SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS,
} from "../constants/config";
import MultiSelectDropDown from "../components/MultiSelectDropDown";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Divider } from "@material-ui/core";
import LSSwitch from "../components/Switch/LsSwitch";
import { FormattedMessage } from "react-intl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import LSInfoIcon from "components/LSInfoIcon";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PhoneNumber from "components/custom/PhoneNumber";

const UI_COMPONENT_TYPES = {
  CHIPS_LIST_BOX: "CHIPS_LIST_BOX",
  OUTLINE_DROPDOWN: "OUTLINE_DROPDOWN",
  OUTLINE_TEXT_BOX: "OUTLINE_TEXT_BOX",
  BUTTON_POSITIVE: "BUTTON_POSITIVE",
  BUTTON_STATIC_POSITIVE: "BUTTON_STATIC_POSITIVE",
  BUTTON_STATIC_NEGATIVE: "BUTTON_STATIC_NEGATIVE",
  ICON_SQUARE_BUTTON_SEARCH: "ICON_SQUARE_BUTTON_SEARCH",
  ICON_HEADER_BUTTON_SETTINGS: "ICON_HEADER_BUTTON_SETTINGS",
  ICON_HEADER_HORIZONTAL_DIVIDER: "ICON_HEADER_HORIZONTAL_DIVIDER",
  ICON_TRANSPARENT_BUTTON_BACK: "ICON_TRANSPARENT_BUTTON_BACK",
  ICON_BORDER_BUTTON_PRIMARY_ADD: "ICON_BORDER_BUTTON_PRIMARY_ADD",
  ICON_BORDER_BUTTON_PRIMARY_GROUPS: "ICON_BORDER_BUTTON_PRIMARY_GROUPS",
  BORDER_BUTTON_PRIMARY_WITHOUT_ICON: "BORDER_BUTTON_PRIMARY_WITHOUT_ICON",
  ICON_BORDER_BUTTON_PRIMARY_IMPORT: "ICON_BORDER_BUTTON_PRIMARY_IMPORT",
  ICON_BORDER_BUTTON_PRIMARY_DOWNLOAD: "ICON_BORDER_BUTTON_PRIMARY_DOWNLOAD",
  BORDER_BUTTON_PRIMARY_DOWNLOAD: "BORDER_BUTTON_PRIMARY_DOWNLOAD",
  HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO:
    "HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO",
  HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO_AND_TEXT_COUNT:
    "HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO_AND_TEXT_COUNT",
  HORIZONTAL_EXPANDABLE_LABEL_WITH_TEXT_COUNT_AND_INPUT_WITHOUT_INFO_AND_LABEL:
    "HORIZONTAL_EXPANDABLE_LABEL_WITH_TEXT_COUNT_AND_INPUT_WITHOUT_INFO_AND_LABEL",
  HORIZONTAL_INPUT_FIELD_TILTED_TITLE_WITH_INFO:
    "HORIZONTAL_INPUT_FIELD_TILTED_TITLE_WITH_INFO",
  LABEL_CONTAINER: "LABEL_CONTAINER",
  HORIZONTAL_LABEL_INPUT: "HORIZONTAL_LABEL_INPUT",
  HORIZONTAL_LABEL_INPUT_WITH_CALENDAR_ICON:
    "HORIZONTAL_LABEL_INPUT_WITH_CALENDAR_ICON",
  HORIZONTAL_LABEL_INPUT_WITHOUT_LABEL: "HORIZONTAL_LABEL_INPUT_WITHOUT_LABEL",
  HORIZONTAL_LABEL_INPUT_WITH_INFO: "HORIZONTAL_LABEL_INPUT_WITH_INFO",
  HORIZONTAL_LABEL_PHONE_INPUT_WITH_INFO:
    "HORIZONTAL_LABEL_PHONE_INPUT_WITH_INFO",
  HORIZONTAL_LABEL_INPUT_PAYSLIP_WITH_INFO:
    "HORIZONTAL_LABEL_INPUT_PAYSLIP_WITH_INFO",
  HORIZONTAL_LABEL_DROPDOWN: "HORIZONTAL_LABEL_DROPDOWN",
  HORIZONTAL_LABEL_DROPDOWN_WITH_INFO: "HORIZONTAL_LABEL_DROPDOWN_WITH_INFO",
  HORIZONTAL_LABEL_TEXT: "HORIZONTAL_LABEL_TEXT",
  HORIZONTAL_LABEL_RIGHT_SWITCH: "HORIZONTAL_LABEL_RIGHT_SWITCH",
  HORIZONTAL_LABEL_RIGHT_SWITCH_WITH_LABEL_INFO:
    "HORIZONTAL_LABEL_RIGHT_SWITCH_WITH_LABEL_INFO",
  HORIZONTAL_LABEL_RIGHT_SWITCH_WITH_LABEL_INFO_SEND_SURVEY:
    "HORIZONTAL_LABEL_RIGHT_SWITCH_WITH_LABEL_INFO_SEND_SURVEY",
  SEARCH_INPUT: "SEARCH_INPUT",
  DROPDOWN: "DROPDOWN",
  DROPDOWN_NON_BORDER: "DROPDOWN_NON_BORDER",
  HORIZONTAL_LABEL_DROPDOWN_NONE_BORDER:
    "HORIZONTAL_LABEL_DROPDOWN_NONE_BORDER",
  VERTICAL_LABEL_CHIPS_DROPDOWN: "VERTICAL_LABEL_CHIPS_DROPDOWN",
  HORIZONTAL_LABEL_CHIPS_DROPDOWN: "HORIZONTAL_LABEL_CHIPS_DROPDOWN",
  HORIZONTAL_NON_LABEL_CHIPS_DROPDOWN: "HORIZONTAL_NON_LABEL_CHIPS_DROPDOWN",
  MENU_POPOVER: "MENU_POPOVER",
  LS_OVERFLOW_MENU: "LS_OVERFLOW_MENU",
  LS_BADGE_LIST: "LS_BADGE_LIST",
  LS_INPUT_LIST_NO_TITLE: "LS_INPUT_LIST_NO_TITLE",
  LS_BADGE_ACTIVE_LIST: "LS_BADGE_ACTIVE_LIST",
  LS_CHECKBOX: "LS_CHECKBOX",
  INPUT_NO_TITLE: "INPUT_NO_TITLE",
  DROPDOWN_NO_TITLE: "DROPDOWN_NO_TITLE",
  DATE_FIELD_NO_TITLE: "DATE_FIELD_NO_TITLE",
  DATE_RANGE_FIELD_NO_TITLE: "DATE_RANGE_FIELD_NO_TITLE",
  FILLED_BUTTON: "FILLED_BUTTON",
  TEXT_BOX_HIGHLIGHT_TITLE: "TEXT_BOX_HIGHLIGHT_TITLE",
  DATE_FIELD: "DATE_FIELD",
  DATE_FIELD_HORIZONTAL: "DATE_FIELD_HORIZONTAL",
  CHIP_ACTIVE_ARRAY_REMOVABLE: "CHIP_ACTIVE_ARRAY_REMOVABLE",
  MULTI_SELECT_DROPDOWN_NO_TITLE: "MULTI_SELECT_DROPDOWN_NO_TITLE",
  MULTI_SELECT_DROPDOWN_WITH_TITLE_AND_CHIP:
    "MULTI_SELECT_DROPDOWN_WITH_TITLE_AND_CHIP",
  BADGE_DATE_RANGE: "BADGE_DATE_RANGE",
  FORM_HORIZONTAL_LABEL_SWITCH: "FORM_HORIZONTAL_LABEL_SWITCH",
  FORM_HORIZONTAL_RIGHT_LABEL_SWITCH: "FORM_HORIZONTAL_RIGHT_LABEL_SWITCH",
  LS_MULTI_SELECT_DROPDOWN: "LS_MULTI_SELECT_DROPDOWN",
  LS_MULTI_SELECT_CHIPS_DROPDOWN: "LS_MULTI_SELECT_CHIPS_DROPDOWN",
  BORDER_LESS_ACTIVE_DOWNLOAD_BUTTON: "BORDER_LESS_ACTIVE_DOWNLOAD_BUTTON",
  BUTTON_BY_STYLE_CLASS: "BUTTON_BY_STYLE_CLASS",
  LS_DATE_TIME_POPUP_WITH_SELECT_AND_IMMEDIATE:
    "LS_DATE_TIME_POPUP_WITH_SELECT_AND_IMMEDIATE",
  BUTTON_DROPDOWN_MENU: "BUTTON_DROPDOWN_MENU",
};

const UIUtils = {
  UI_COMPONENT_TYPES: UI_COMPONENT_TYPES,

  getUIComponent: function(params) {
    try {
      switch (params.type) {
        case UI_COMPONENT_TYPES.CHIPS_LIST_BOX: {
          return this.renderChipsListBox(params);
        }

        case UI_COMPONENT_TYPES.OUTLINE_DROPDOWN: {
          return this.renderOutlineDropdown(params);
        }

        case UI_COMPONENT_TYPES.OUTLINE_TEXT_BOX: {
          return this.renderOutlineTextBox(params);
        }

        case UI_COMPONENT_TYPES.LS_MULTI_SELECT_DROPDOWN: {
          return this.renderMultiSelectDropdown(params);
        }

        case UI_COMPONENT_TYPES.FORM_HORIZONTAL_LABEL_SWITCH: {
          return this.getFormHorizontalLabelSwitch(params);
        }

        case UI_COMPONENT_TYPES.FORM_HORIZONTAL_RIGHT_LABEL_SWITCH: {
          return this.getFormHorizontalRightLabelSwitch(params);
        }

        case UI_COMPONENT_TYPES.BADGE_DATE_RANGE: {
          return this.getBadgeDateRange(params);
        }

        case UI_COMPONENT_TYPES.CHIP_ACTIVE_ARRAY_REMOVABLE: {
          return this.getActiveChipArray(params);
        }

        case UI_COMPONENT_TYPES.DATE_FIELD_HORIZONTAL: {
          return this.getDateFieldHorizontal(params);
        }

        case UI_COMPONENT_TYPES.DATE_FIELD: {
          return this.getDateField(params);
        }

        case UI_COMPONENT_TYPES.TEXT_BOX_HIGHLIGHT_TITLE: {
          return this.getTextBoxHighLightTitle(params);
        }

        case UI_COMPONENT_TYPES.LS_INPUT_LIST_NO_TITLE: {
          return this.getNoTitleInputList(params);
        }

        case UI_COMPONENT_TYPES.LS_CHECKBOX: {
          return this.getLSCheckBox(params);
        }

        case UI_COMPONENT_TYPES.LS_BADGE_ACTIVE_LIST: {
          return this.getBadgeActiveList(params);
        }

        case UI_COMPONENT_TYPES.LS_BADGE_LIST: {
          return this.getBadgeMenu(params);
        }

        case UI_COMPONENT_TYPES.LS_OVERFLOW_MENU: {
          return this.getLSOverflowMenu(params);
        }

        case UI_COMPONENT_TYPES.MENU_POPOVER: {
          return this.getMenuPopOver(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_NON_LABEL_CHIPS_DROPDOWN: {
          return this.getNonLabelChipsLayout(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_CHIPS_DROPDOWN: {
          return this.getHorizontalChipsLayout(params);
        }

        case UI_COMPONENT_TYPES.VERTICAL_LABEL_CHIPS_DROPDOWN: {
          return this.getVerticalLabelChipsDropdown(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN_NONE_BORDER: {
          return this.getHorizontalLabelDropdownNoneBorder(params);
        }

        case UI_COMPONENT_TYPES.DROPDOWN_NON_BORDER: {
          return this.getDropdownNoneBorder(params);
        }

        case UI_COMPONENT_TYPES.DROPDOWN: {
          return this.getDropdown(params);
        }

        case UI_COMPONENT_TYPES.SEARCH_INPUT: {
          return this.getSearchInput(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_RIGHT_SWITCH: {
          return this.getHorizontalLabelRightSwitch(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_RIGHT_SWITCH_WITH_LABEL_INFO: {
          return this.getHorizontalLabelRightSwitchWithLabelAndInfo(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_RIGHT_SWITCH_WITH_LABEL_INFO_SEND_SURVEY: {
          return this.getHorizontalLabelRightSwitchWithLabelAndInfoSendSurvey(
            params
          );
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_INPUT_FIELD_TILTED_TITLE_WITH_INFO: {
          return this.getHorizontalInputWithTiltedTitleAndInfo(params);
        }
        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_TEXT: {
          return this.getHorizontalLabelText(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN: {
          return this.getHorizontalLabelDropdown(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN_WITH_INFO: {
          return this.getHorizontalLabelDropdownWithInfo(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_WITH_INFO: {
          return this.getHorizontalLabelInputWithInfo(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_PHONE_INPUT_WITH_INFO: {
          return this.getPhoneInput(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_PAYSLIP_WITH_INFO: {
          return this.getHorizontalLabelPayslipInputWithInfo(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO: {
          return this.getHorizontalExapandableLabelInputWithInfo(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_EXPANDABLE_LABEL_INPUT_WITH_INFO_AND_TEXT_COUNT: {
          return this.getHorizontalExapandableLabelInputWithInfoAndTextCount(
            params
          );
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_EXPANDABLE_LABEL_WITH_TEXT_COUNT_AND_INPUT_WITHOUT_INFO_AND_LABEL: {
          return this.getHorizontalExapandableLabelInputWithTextCountWithoutInfoAndLabel(
            params
          );
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT: {
          return this.getHorizontalLabelInput(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_WITH_CALENDAR_ICON: {
          return this.getHorizontalLabelInputWithCalendarIcon(params);
        }

        case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT_WITHOUT_LABEL: {
          return this.getHorizontalLabelInputWithDropdownIconWithoutLabel(
            params
          );
        }

        case UI_COMPONENT_TYPES.LABEL_CONTAINER: {
          return this.getLabelContainer(params);
        }

        case UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE: {
          return this.getStaticActionButtonNegative(params);
        }

        case UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE: {
          return this.getStaticActionButtonPositive(params);
        }

        case UI_COMPONENT_TYPES.FILLED_BUTTON: {
          return this.getHeaderActionButtonFilledPositive(params);
        }

        case UI_COMPONENT_TYPES.BUTTON_POSITIVE: {
          return this.getHeaderActionButtonPositive(params);
        }

        case UI_COMPONENT_TYPES.ICON_SQUARE_BUTTON_SEARCH: {
          return this.getSquareIconButton(params, "button_icon_search");
        }

        case UI_COMPONENT_TYPES.ICON_HEADER_HORIZONTAL_DIVIDER: {
          return this.getHeaderHorizontalDivider(params);
        }

        case UI_COMPONENT_TYPES.ICON_HEADER_BUTTON_SETTINGS: {
          return this.getIconButton(params, "button_icon_settings");
        }

        case UI_COMPONENT_TYPES.ICON_TRANSPARENT_BUTTON_BACK: {
          return this.getIconButton(params, "button_icon_back");
        }

        case UI_COMPONENT_TYPES.ICON_BORDER_BUTTON_PRIMARY_ADD: {
          return this.getIconBorderButton(
            params,
            "ls_button_icon ls_button_icon_plus_active"
          );
        }

        case UI_COMPONENT_TYPES.ICON_BORDER_BUTTON_PRIMARY_GROUPS: {
          return this.getIconBorderButton(
            params,
            "ls_button_icon ls_button_icon_groups_active"
          );
        }

        case UI_COMPONENT_TYPES.BORDER_BUTTON_PRIMARY_WITHOUT_ICON: {
          return this.getBorderButtonWithoutIcon(params);
        }

        case UI_COMPONENT_TYPES.ICON_BORDER_BUTTON_PRIMARY_IMPORT: {
          return this.getIconBorderButton(
            params,
            "ls_button_icon ls_button_icon_import_active"
          );
        }

        case UI_COMPONENT_TYPES.ICON_BORDER_BUTTON_PRIMARY_DOWNLOAD: {
          return this.getIconBorderButton(
            params,
            "ls_button_icon ls_button_icon_download_active"
          );
        }

        case UI_COMPONENT_TYPES.BORDER_BUTTON_PRIMARY_DOWNLOAD: {
          return this.getIconBorderButton(params, "");
        }

        case UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_WITH_TITLE_AND_CHIP: {
          return this.getMultiSelectDropDownWithChip();
        }

        case UI_COMPONENT_TYPES.BORDER_LESS_ACTIVE_DOWNLOAD_BUTTON: {
          return this.getDownloadButton(
            params,
            "ls_button_icon ls_button_icon_download_active"
          );
        }

        case UI_COMPONENT_TYPES.BUTTON_BY_STYLE_CLASS: {
          return this.getButtonByStyleClass(params);
        }

        case UI_COMPONENT_TYPES.LS_DATE_TIME_POPUP_WITH_SELECT_AND_IMMEDIATE: {
          return this.getDateTimePopUpWithSelectTimeAndImmediate();
        }

        case UI_COMPONENT_TYPES.BUTTON_DROPDOWN_MENU: {
          return this.getButtonDropdownMenu(params);
        }

        default: {
          return null;
        }
      }
    } catch (ex) {
      console.error("getUIComponent : error : ", ex);
    }
  },

  renderChipsListBox: function(params) {
    return (
      <div className="chips_list_box_container" style={params["style"]}>
        {params["items"] &&
          params["items"].map((item, index) => {
            return item["isActive"] ? (
              <Chip
                className={"mb-2 " + "ls_theme_active_chip_background hand"}
                label={
                  item.label
                    ? item.label.length > 35
                      ? item.label.substr(0, 34) + " ..."
                      : item.label
                    : ""
                }
                key={item.id}
                deleteIcon={<Close />}
                onDelete={() => {
                  if (params.onClick) {
                    params.onClick(params["key"], item);
                  }
                }}
              />
            ) : (
              <Chip
                className={"mb-2 " + "ls_theme_inactive_chip_background"}
                label={item.label ? item.label : ""}
                key={item.id}
              />
            );
          })}
      </div>
    );
  },

  renderOutlineDropdown: function(params) {
    return (
      <div className="form-group outline_dropdown" style={params["style"]}>
        <TextField
          error={params["isError"]}
          margin="normal"
          fullWidth
          id={params["key"]}
          value={params["value"]}
          onChange={(event) => {
            if (params.changeCallback) {
              params.changeCallback(params["key"], event.target.value);
            }
          }}
          onBlur={() => {
            if (params.blurCallback) {
              params.blurCallback(params["key"]);
            }
          }}
          InputLabelProps={{
            shrink: params["value"] ? true : false,
            style: params["inputLabelStyle"],
            className: params["labelClassName"],
          }}
          disabled={params["disabled"]}
          select={true}
          label={
            params["localisation"] && (
              <span
                className={
                  params["disabled"]
                    ? "outline_dropdown_disabled_header_text"
                    : ""
                }
              >
                <IntlMessages id={params["localisation"]} />
              </span>
            )
          }
          SelectProps={{ native: true }}
          helperText={params["helperText"]}
          variant={"outlined"}
          size={params["size"]}
          FormHelperTextProps={{
            className: params["helperTextStyle"],
          }}
          islength={params["islength"]}
        >
          {params["islength"]
            ? params["items"].map((option, index) => {
                return (
                  <>
                    {option["key"] && option["key"].includes("custom-") ? (
                      <FormattedMessage id={option["label"]} key={index}>
                        {(message) => (
                          <option
                            value={option["value"]}
                            disabled={params["disabledOption"]}
                            style={{ fontWeight: "bold" }}
                          >
                            {" "}
                            {message}{" "}
                          </option>
                        )}
                      </FormattedMessage>
                    ) : (
                      <FormattedMessage id={option["label"]} key={index}>
                        {(message) => (
                          <option
                            value={option["value"]}
                            disabled={params["disabledOption"]}
                          >
                            {" "}
                            {message}{" "}
                          </option>
                        )}
                      </FormattedMessage>
                    )}
                    {index ===
                      params["items"].length - params["islength"] - 1 && (
                      <Divider />
                    )}
                  </>
                );
              })
            : params["items"].map((option, index) => {
                return (
                  <FormattedMessage id={option["label"]} key={index}>
                    {(message) => (
                      <option
                        value={option["value"]}
                        disabled={params["disabledOption"]}
                        className={
                          params["disabled"]
                            ? "outline_dropdown_disabled_header_text"
                            : ""
                        }
                      >
                        {" "}
                        {message}
                      </option>
                    )}
                  </FormattedMessage>
                );
              })}
        </TextField>
      </div>
    );
  },

  renderOutlineTextBox: function(params) {
    return (
      <div className="form-group outline_dropdown" style={params["style"]}>
        <TextField
          error={params["isError"]}
          margin="normal"
          fullWidth
          id={params["key"]}
          value={params["value"]}
          onChange={(event) => {
            if (params.changeCallback) {
              params.changeCallback(params["key"], event.target.value);
            }
          }}
          onBlur={() => {
            if (params.blurCallback) {
              params.blurCallback(params["key"]);
            }
          }}
          InputLabelProps={{
            shrink: params["value"] ? true : false,
            style: params["inputLabelStyle"],
          }}
          disabled={params["disabled"]}
          select={false}
          label={
            params["localisation"] && (
              <span
                className={
                  params["disabled"]
                    ? "outline_dropdown_disabled_header_text"
                    : ""
                }
              >
                <IntlMessages id={params["localisation"]} />
              </span>
            )
          }
          SelectProps={{ native: true }}
          helperText={params["helperText"]}
          variant={"outlined"}
        ></TextField>
      </div>
    );
  },

  renderMultiSelectDropdown: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <MultiSelectDropDown data={params} />
        </div>
        <div
          className="input_container_horizontal_label_parent"
          style={{ paddingTop: "12px" }}
        >
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getFormHorizontalLabelSwitch: function(params) {
    return (
      <div className="input_container_no_margin form_horizontal_switch_border">
        <div className="input_container_label_no_padding">
          <div className="form_container_input_parent_right">
            <span className="form_horizontal_switch_info_switch_parent">
              <Switch
                classes={{
                  checked: "text-primary",
                  bar: "bg-success",
                }}
                checked={params["value"]}
                onChange={(e) => {
                  if (params.changeCallback) {
                    params.changeCallback(params["key"], e.target.value);
                  }
                }}
                aria-label={params["key"]}
              />
            </span>

            {/* <span className="form_horizontal_switch_info_icon_parent">
                            <div className="form_horizontal_switch_info_icon"></div>
                        </span> */}
            <LSInfoIcon
              info={
                <IntlMessages id="DIC_MAIN_PAGE_MESSAGE_INFO_NEW_PAY_SLIP" />
              }
            />
          </div>

          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getFormHorizontalRightLabelSwitch: function(params) {
    return (
      <div className="input_container_no_margin">
        <div className="input_container_label_no_padding">
          <div className="form_container_input_parent">
            <span className="form_horizontal_switch_info_switch_parent">
              <Switch
                classes={{
                  checked: "text-primary",
                  bar: "bg-success",
                }}
                checked={params["value"]}
                onChange={(e) => {
                  if (params.changeCallback) {
                    params.changeCallback(params["key"], e.target.value);
                  }
                }}
                aria-label={params["key"]}
                disabled={params["disabled"]}
              />
            </span>

            <div className="float-left">
              {UIUtils.getComponentLabel(params)}
            </div>
            {params["toastLabel"] && (
              <span className="form_horizontal_switch_info_icon_left_parent">
                <div className="form_horizontal_switch_info_icon" />
              </span>
            )}
          </div>
        </div>
      </div>
    );
  },

  getBadgeDateRange: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        <div className="ls_theme_vertical_form_container_label">
          {UIUtils.getLSThemeComponentLabelBold(params)}
        </div>

        <div className="ls_theme_vertical_form_container_chip_array_container">
          <Badge
            color={"light pl-2 pr-2 pt-1 pb-1"}
            className={"badge-light"}
            onClick={() =>
              params.onClick && params.onClick(params["key"], true)
            }
            pill
          >
            <label className="ls_badge_date_range_label hand pl-1 pr-1">
              {params["super_label"]}
            </label>
          </Badge>
        </div>

        <WoVoDateRangePicker
          selectionRange={params["value"]}
          isVisible={params["isHidden"]}
          callbackCancel={() =>
            params.onClick && params.onClick(params["key"], false)
          }
          callbackOK={(selectionRange) =>
            params.changeCallback &&
            params.changeCallback(params["key"], selectionRange)
          }
        />
      </div>
    );
  },

  getIconBorderButton: function(params, iconClass) {
    return (
      <Button
        id={params["key"]}
        disabled={params["disabled"]}
        variant="contained"
        className={"jr-btn button_primary_border"}
        onClick={() => {
          if (params.onClick) {
            params.onClick(params["key"]);
          }
        }}
      >
        {params["icon"] ? (
          <>{params["icon"]}</>
        ) : (
          <span className={iconClass} />
        )}
        <span
          className={
            "p-1 " +
            (params["disabled"]
              ? "button_text_color_disabled"
              : "button_text_color_primary")
          }
        >
          {params["label_id"] ? (
            <IntlMessages id={params["label_id"]} />
          ) : params["label"] ? (
            params["label"]
          ) : (
            ""
          )}
        </span>
      </Button>
    );
  },

  getBorderButtonWithoutIcon: function(params) {
    return (
      <Button
        disabled={params["disabled"]}
        variant="contained"
        className={"jr-btn button_primary_border"}
        onClick={() => {
          if (params.onClick) {
            params.onClick(params["key"]);
          }
        }}
      >
        <span
          className={
            "p-1 " +
            (params["disabled"]
              ? "button_text_color_disabled"
              : "button_text_color_primary")
          }
        >
          {params["label_id"] ? (
            <IntlMessages id={params["label_id"]} />
          ) : params["label"] ? (
            params["label"]
          ) : (
            ""
          )}
        </span>
      </Button>
    );
  },

  /** table custom checkbox **/
  getLSCheckBox: function(params) {
    let clickHandler = function(event) {
      if (params.onClick) {
        params.onClick(params.key, params.data, event);
      }
    };

    return (
      <div
        className={"hand " + (params.className ? params.className : "")}
        onClick={clickHandler}
      >
        <div
          className={
            (params.checked
              ? "info_round_checkbox_checked"
              : "info_round_checkbox") + " ls_table_header_checkbox_icon"
          }
        />
      </div>
    );
  },

  /** left or right side overflow filter menu **/
  getBadgeActiveList: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        {params["items"].map(function(item, idx) {
          return (
            <Button
              variant="contained"
              color="primary"
              className="jr-btn jr-btn-label right ls_active_badge"
              onClick={() => params.changeCallback(item)}
            >
              <i className="zmdi zmdi-close zmdi-hc-fw ls_active_badge_label_icon" />
              <span className="ls_active_badge_label">{item.label}</span>
            </Button>
          );
        })}
      </div>
    );
  },

  /** to get the date field horizontal**/
  getDateFieldHorizontal: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <DatePickers
            defaultValue={params["value"]}
            minDate={params["minDate"]}
            onChange={(e) => {
              if (params.changeCallback) {
                params.changeCallback(params["key"], e);
              }
            }}
            onBlur={(e) => {
              if (params.handleBlur) {
                params.handleBlur(params["key"]);
              }
            }}
            variant={params["variant"]}
            placeholder={params["placeholder"]}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  /** to get the date field **/
  getDateField: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        <div className="ls_theme_vertical_form_container_label">
          {UIUtils.getLSThemeComponentLabel(params)}
        </div>
        <div className="input_container_label">
          <div className="form_container_input_parent">
            <div className={"ls_left_side_filter_block "}>
              <DatePickers
                placeholder={
                  params.placeHolderKey
                    ? "   " +
                      AppUtils.getTranslationFromKey(params.placeHolderKey)
                    : params.placeHolder
                }
                defaultValue={params["value"]}
                minDate={params["minDate"]}
                onChange={(e) => {
                  if (params.changeCallback) {
                    params.changeCallback(params["key"], e);
                  }
                }}
                onBlur={(e) => {
                  if (params.handleBlur) {
                    params.handleBlur(params["key"]);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },

  /** left or right side overflow filter menu **/
  getTextBoxHighLightTitle: function(params) {
    return (
      <TextField
        id={params.key && params.key}
        label={params.title && <IntlMessages id={params.title} />}
        placeholder={
          params.placeholder &&
          LanguageUtils.getLocalisationByKey(params.placeholder)
        }
        error={params["isError"]}
        fullWidth
        value={params["value"]}
        onChange={(event) => {
          if (params.changeCallback) {
            params.changeCallback(params["key"], event.target.value);
          }
        }}
        onBlur={() => {
          if (params.blurCallback) {
            params.blurCallback(params["key"]);
          }
        }}
        disabled={params["disabled"]}
        SelectProps={params["selectProps"]}
        helperText={params["helperText"]}
        variant={params["variant"]}
      />
    );
  },

  /** left or right side overflow filter menu **/
  getNoTitleInputList: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        <div className="ls_theme_vertical_form_container_label">
          {UIUtils.getLSThemeComponentLabelBold(params)}
        </div>

        <div className="no_title_input_container">
          {params["items"].map(function(item, idx) {
            let selectedItems = [];

            if (
              item.type ===
              UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE
            ) {
              selectedItems =
                params.getChildComponentValue &&
                params.getChildComponentValue(
                  item.type,
                  params["key"],
                  item["key"]
                );
            }

            return (
              <div
                key={idx}
                className={
                  "ls_input_list_non_title_sub_content " +
                  (item.className && item.className)
                }
              >
                {item.type === UIUtils.UI_COMPONENT_TYPES.INPUT_NO_TITLE && (
                  <TextField
                    multiline
                    required={item["required"]}
                    fullWidth
                    className="jr-wall-textarea"
                    margin="none"
                    minRows="1"
                    id={params["key"]}
                    variant={item["variant"]}
                    placeholder={
                      item.placeHolderKey
                        ? "   " +
                          AppUtils.getTranslationFromKey(item.placeHolderKey)
                        : item.placeHolder
                    }
                    label={
                      item.label
                        ? "   " + AppUtils.getTranslationFromKey(item.label)
                        : ""
                    }
                    value={
                      params.getChildComponentValue &&
                      params.getChildComponentValue(
                        item.type,
                        params["key"],
                        item["key"]
                      )
                    }
                    onChange={(event) => {
                      if (params.setChildComponentValue) {
                        params.setChildComponentValue(
                          item.type,
                          params["key"],
                          item["key"],
                          event.target.value
                        );
                      }
                    }}
                  />
                )}

                {item.type === UIUtils.UI_COMPONENT_TYPES.DROPDOWN_NO_TITLE && (
                  <IntegrationReactSelect
                    items={
                      params.getChildComponentChildList &&
                      params.getChildComponentChildList(
                        item.type,
                        params["key"],
                        item["key"]
                      )
                    }
                    onChange={(param_one, param_two) => {
                      if (params.setChildComponentValue) {
                        params.setChildComponentValue(
                          item.type,
                          params["key"],
                          item.key,
                          param_two
                        );
                      }
                    }}
                    onBlur={(e) => {
                      if (params.handleBlur) {
                        params.handleBlur(params["key"]);
                      }
                    }}
                    content_id={params["key"]}
                    value={
                      params.getChildComponentValue &&
                      params.getChildComponentValue(
                        item.type,
                        params["key"],
                        item["key"]
                      )
                    }
                    defaultValue={
                      params.getChildComponentValue &&
                      params.getChildComponentValue(
                        item.type,
                        params["key"],
                        item["key"]
                      )
                    }
                    id={params["key"]}
                    variant={item["variant"]}
                    placeholder={
                      item.placeHolderKey
                        ? "   " +
                          AppUtils.getTranslationFromKey(item.placeHolderKey)
                        : item.placeHolder
                    }
                    label={
                      item.label
                        ? "   " + AppUtils.getTranslationFromKey(item.label)
                        : ""
                    }
                  />
                )}

                {item.type ===
                  UIUtils.UI_COMPONENT_TYPES.MULTI_SELECT_DROPDOWN_NO_TITLE && (
                  <>
                    <Multiselect
                      required={item.required}
                      options={
                        params.getChildComponentChildList &&
                        params.getChildComponentChildList(
                          item.type,
                          params["key"],
                          item["key"]
                        )
                      }
                      selectedValues={selectedItems}
                      onSelect={(selectedList, selectedItem) => {
                        if (params.setChildComponentValue) {
                          params.setChildComponentValue(
                            item.type,
                            params["key"],
                            item.key,
                            selectedList
                          );
                        }
                      }}
                      onRemove={(selectedList, removedItem) => {
                        if (params.setChildComponentValue) {
                          params.setChildComponentValue(
                            item.type,
                            params["key"],
                            item.key,
                            selectedList
                          );
                        }
                      }}
                      displayValue={"label"}
                      placeholder={
                        selectedItems.length === 0
                          ? item.placeHolderKey
                            ? "   " +
                              AppUtils.getTranslationFromKey(
                                item.placeHolderKey
                              )
                            : item.placeHolder
                          : AppUtils.getTranslationFromKey(
                              "DIC_KEY_WC_VIEW_LABEL_TYPE_HERE"
                            )
                      }
                      singleSelect={false}
                      showCheckbox={true}
                      closeIcon={"cancel"}
                      counter={params["counter"]}
                    />

                    {selectedItems.length > 0 && (
                      <span className="multi_select_dropdown_no_title_label">
                        {item.label
                          ? AppUtils.getTranslationFromKey(item.label)
                          : ""}
                      </span>
                    )}
                  </>
                )}

                {item.type ===
                  UIUtils.UI_COMPONENT_TYPES.DATE_FIELD_NO_TITLE && (
                  <DatePickers
                    defaultValue={
                      params.getChildComponentValue &&
                      params.getChildComponentValue(
                        item.type,
                        params["key"],
                        item["key"]
                      )
                    }
                    minDate={item["minDate"]}
                    onChange={(e) => {
                      if (params.setChildComponentValue) {
                        params.setChildComponentValue(
                          item.type,
                          params["key"],
                          item["key"],
                          e
                        );
                      }
                    }}
                    id={params["key"]}
                    variant={item["variant"]}
                    placeholder={
                      item.placeHolderKey
                        ? "   " +
                          AppUtils.getTranslationFromKey(item.placeHolderKey)
                        : item.placeHolder
                    }
                    label={
                      item.label
                        ? "   " + AppUtils.getTranslationFromKey(item.label)
                        : ""
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  },

  /** left or right side overflow filter menu **/
  getBadgeMenu: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        <div className="ls_theme_vertical_form_container_label">
          {UIUtils.getLSThemeComponentLabelBold(params)}
        </div>

        <div className="input_container_label float-left">
          {params["items"].map(function(item, idx) {
            return (
              <Badge
                key={idx}
                color={"light pl-2 pr-2 pt-1 pb-1"}
                className={
                  params.getChildComponentValue(
                    params.type,
                    params.key,
                    item.key
                  )
                    ? item.activeClass
                      ? item.activeClass
                      : "badge-light-active"
                    : "badge-light"
                }
                onClick={() =>
                  params.setChildComponentValue &&
                  params.setChildComponentValue(
                    params.type,
                    params.key,
                    item.key,
                    !params.getChildComponentValue(
                      params.type,
                      params.key,
                      item.key
                    )
                  )
                }
                pill
              >
                <label style={{ cursor: "pointer" }}>{item.label}</label>
              </Badge>
            );
          })}
        </div>
      </div>
    );
  },

  getActiveChipArray: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        <div className="ls_theme_vertical_form_container_label">
          {UIUtils.getLSThemeComponentLabelBold(params)}
        </div>

        <div className="ls_theme_vertical_form_container_chip_array_container">
          {(params["items"] || []).map(function(item, idx) {
            return (
              <Chip
                deleteIcon={<Close />}
                className="ls_theme_active_chip"
                label={item.label ? item.label : item.value ? item.value : ""}
                key={`${item.id}-${item.key}-${idx}`}
                onDelete={() => {
                  params.changeCallback(params["key"], item);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  },

  /** left or right side overflow filter menu **/
  getLSOverflowMenu: function(params) {
    return <LSOverflowMenu params={params} />;
  },

  getMenuPopOver: function(params) {
    let defaultTitle = "";

    if (params["isValueTitle"]) {
      defaultTitle = <IntlMessages id="DIC_KEY_PAYSLIP_LIST_ALL_OPTION" />;
      if (typeof params["value"] != "undefined" && params["value"] !== "") {
        defaultTitle = params["value"];
      }
    } else {
      if (typeof params["label"] != "undefined") {
        defaultTitle = params["label"];
      } else if (typeof params["localisation"] != "undefined") {
        defaultTitle = <IntlMessages id={params["localisation"]} />;
      }
    }

    return (
      <div className="form-group" style={params["style"]}>
        <div
          className={"menu_popover_button"}
          id={params["key"]}
          onClick={(event) => {
            if (params.menuTitleClick) {
              params.menuTitleClick(params["key"], event);
            }
          }}
        >
          <span className={"menu_popover_button_icon"} />
          <span className="pl-1 pt-1 menu_popover_button_title ">
            {defaultTitle}
          </span>
        </div>

        <Menu
          id={params["key"]}
          anchorEl={params["anchorEl"]}
          open={Boolean(params["menuState"])}
          onClose={() =>
            params.handleRequestClose &&
            params.handleRequestClose(params["key"])
          }
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {params["items"].map((option) => {
            return (
              <MenuItem
                key={option["value"]}
                value={option["value"]}
                onClick={() => {
                  if (params.handleRequestClose) {
                    params.handleRequestClose(params["key"]);
                  }
                  if (params.changeCallback) {
                    params.changeCallback(option["key"], option);
                  }
                }}
              >
                {option["localisation"] ? (
                  <IntlMessages id={option["localisation"]} />
                ) : (
                  ""
                )}
                {option["label"] ? option["label"] : ""}
                {option["content"] ? option["content"] : ""}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  },

  getVerticalLabelChipsDropdown: function(params) {
    return (
      <div className="ls_theme ls_theme_vertical_form_container">
        <div className="ls_theme_vertical_form_container_label">
          {UIUtils.getLSThemeComponentLabel(params)}
        </div>
        <div className="input_container_label">
          <div className="form_container_input_parent">
            <MultiSelectChipSelect
              id={params["key"]}
              items={params["items"]}
              selectedItems={params["value"]}
              placeholder={<IntlMessages id={params["localisation"]} />}
              callback={params.changeCallback}
              disabled={params["disabled"]}
            />
          </div>
        </div>
      </div>
    );
  },

  getDropdownNoneBorder: function(params) {
    return (
      <div className="input_container non_border">
        <div className="form_container_input_parent">
          <IntegrationReactSelect
            items={params["items"]}
            onChange={params.changeCallback}
            onBlur={(e) => {
              if (params.handleBlur) {
                params.handleBlur(params["key"]);
              }
            }}
            content_id={params["key"]}
            value={params["value"]}
            defaultValue={params["defaultValue"]}
            disabled={params["disabled"]}
          />
        </div>
      </div>
    );
  },

  getDropdown: function(params) {
    return (
      <div className="input_container">
        <div className="form_container_input_parent">
          <IntegrationReactSelect
            items={params["items"]}
            onChange={params.changeCallback}
            onBlur={(e) => {
              if (params.handleBlur) {
                params.handleBlur(params["key"]);
              }
            }}
            content_id={params["key"]}
            value={params["value"]}
            defaultValue={params["defaultValue"]}
            disabled={params["disabled"]}
          />
        </div>
      </div>
    );
  },

  getSearchInput: function(params) {
    const showCancelButton =
      params["withCancel"] && !!params["value"] && params["value"] !== "";
    const endAdornment = params["withCancel"] ? (
      <IconButton
        disabled={!showCancelButton}
        style={{ padding: "9.5px" }}
        onClick={() => {
          if (params.changeCallback) {
            params.changeCallback(params["key"], "");
          }
          if (params.onClick) {
            params.onClick(params["key"], "");
          }
        }}
      >
        <Cancel fontSize="small" />
      </IconButton>
    ) : null;
    return (
      <div className="search_input_container">
        <Input
          value={params["value"]}
          onChange={(e) => {
            if (params.changeCallback) {
              params.changeCallback(params["key"], e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.key == "Enter") {
              if (params.onClick) {
                params.onClick(params["key"], e.target.value);
              }
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <div className="input_search_icon" />
            </InputAdornment>
          }
          endAdornment={endAdornment}
          placeholder={AppUtils.getTranslationFromKey(params.placeholder)}
        />
      </div>
    );
  },

  getStaticActionButtonNegative: function(params) {
    try {
      return (
        <Button
          id={params.key}
          aria-haspopup="true"
          className={
            "staticActionButtonNegative " + (params.isHidden ? "d-none" : "")
          }
          onClick={(event) => {
            if (params.onClick) {
              params.onClick(params.key);
            }
          }}
          disabled={params.disabled}
        >
          <span>{params && params.title}</span>
        </Button>
      );
    } catch (ex) {
      console.error("getStaticActionButtonNegative : error : ", ex);
    }
  },

  getStaticActionButtonPositive: function(params) {
    try {
      return (
        <Button
          id={params.key}
          aria-haspopup="true"
          className={
            "staticActionButton " +
            (params.disabled ? " staticActionButtonDisabled " : "") +
            (params.isHidden ? "d-none" : "")
          }
          onClick={(event) => {
            if (params.onClick) {
              params.onClick(params.key);
            }
          }}
          disabled={params.disabled}
        >
          <span>{params && params.title}</span>
        </Button>
      );
    } catch (ex) {
      console.error("getStaticActionButtonPositive : error : ", ex);
    }
  },

  getHeaderActionButtonFilledPositive: function(params) {
    try {
      return (
        <div
          className={
            "filter_button_full " +
            (params["disabled"] ? "filter_button_full_disabled" : "")
          }
          id={params.key}
          onClick={(event) => {
            if (params.onClick && !params["disabled"]) {
              params.onClick(params.key);
            }
          }}
        >
          {params && params.title}
        </div>
      );
    } catch (ex) {
      console.error("getHeaderActionButtonFilledPositive : error : ", ex);
    }
  },

  getHeaderActionButtonPositive: function(params) {
    try {
      return (
        <Button
          id={params.key}
          aria-haspopup="true"
          className="headerButtonFilled"
          onClick={(event) => {
            if (params.onClick) {
              params.onClick(params.key);
            }
          }}
        >
          {params && params.title}
        </Button>
      );
    } catch (ex) {
      console.error("getHeaderActionButtonPositive : error : ", ex);
    }
  },

  getSquareIconButton: function(params, icon_class) {
    return (
      <div
        className="headerButton button_icon_square"
        onClick={(event) => {
          if (params.onClick) {
            params.onClick(params.key);
          }
        }}
      >
        <div className={icon_class} />
        {params["super_label"] && (
          <span className="ls_filter_button_badge">
            {params["super_label"]}
          </span>
        )}
      </div>
    );
  },

  getHeaderHorizontalDivider: function(params) {
    return <div className="header_horizontal_divider"></div>;
  },

  getIconButton: function(params, icon_class) {
    return (
      <div
        className="button_icon_transparent"
        onClick={(event) => {
          if (params.onClick) {
            params.onClick(params.key);
          }
        }}
      >
        <div className={icon_class} />
      </div>
    );
  },

  getLabelContainer: function(params) {
    return <div className="label_container">{params && params.title}</div>;
  },

  getHorizontalLabelText: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <span className="input_container_text">{params["value"]}</span>
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelRightSwitch: function(params) {
    return (
      <div className="input_container">
        <div className="input_container_label">
          <div className="form_container_input_parent_right">
            {UIUtils.getComponentLabel(params)}
            <LSSwitch
              key={params.key}
              changeCallback={() => params.changeCallback(params.key)}
              value={params.value}
            />
          </div>
        </div>
      </div>
    );
  },

  getHorizontalLabelRightSwitchWithLabelAndInfo: function(params) {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ paddingTop: "0.5rem" }}>
          {UIUtils.getComponentLabel(params)}
        </div>
        <div>
          <LSSwitch
            key={params.key}
            changeCallback={() => params.changeCallback(params.key)}
            value={params.value}
          />
          <div className="float-right">
            <LSInfoIcon
              info={
                params.key === "channelApp" ? (
                  <IntlMessages id="DIC_MAIN_PAGE_APP_INFO_NEW_PAY_SLIP" />
                ) : (
                  <IntlMessages id="DIC_MAIN_PAGE_SMS_INFO_NEW_PAY_SLIP" />
                )
              }
            />
          </div>
        </div>
      </div>
    );
  },

  getHorizontalLabelRightSwitchWithLabelAndInfoSendSurvey: function(params) {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ paddingTop: "0.5rem" }}>
          {UIUtils.getComponentLabel(params)}
        </div>
        <div>
          <LSSwitch
            key={params.key}
            changeCallback={() => params.changeCallback(params.key)}
            value={params.value}
            disabled={params.disabled}
          />
          <div className="float-right">
            <LSInfoIcon info={<IntlMessages id={params.infolabel} />} />
          </div>
        </div>
      </div>
    );
  },

  getHorizontalLabelDropdown: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <IntegrationReactSelect
            inputId={params["key"]}
            items={params["items"]}
            onChange={params.changeCallback}
            onBlur={(e) => {
              if (params.handleBlur) {
                params.handleBlur(params["key"]);
              }
            }}
            content_id={params["key"]}
            value={params["value"]}
            defaultValue={params["defaultValue"]}
            disabled={params["disabled"]}
            variant={params["variant"]}
            placeholder={params["placeholder"]}
            isOverflowEnabled={params["isOverflowEnabled"]}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelDropdownWithInfo: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <div className="label_input_with_icon_container_input">
            <IntegrationReactSelect
              inputId={params["key"]}
              items={params["items"]}
              onChange={params.changeCallback}
              onBlur={(e) => {
                if (params.handleBlur) {
                  params.handleBlur(params["key"]);
                }
              }}
              content_id={params["key"]}
              value={params["value"]}
              defaultValue={params["defaultValue"]}
              disabled={params["disabled"]}
              variant={params["variant"]}
              placeholder={params["placeholder"]}
              isOverflowEnabled={params["isOverflowEnabled"]}
            />
          </div>
          {params["toastLabel"] && (
            <Tooltip
              id={params["key"] + "_tooltip"}
              title={params["toastLabel"]}
              placement="bottom"
              arrow={true}
            >
              <div className="label_input_with_icon_container_icon">
                <div className="info_round_icon ls_table_header_info_icon hand" />
              </div>
            </Tooltip>
          )}
        </div>

        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelDropdownNoneBorder: function(params) {
    return (
      <div className="input_container non_border">
        <div className="input_container_label">
          <div className="form_container_input_parent">
            <IntegrationReactSelect
              items={params["items"]}
              onChange={params.changeCallback}
              onBlur={(e) => {
                if (params.handleBlur) {
                  params.handleBlur(params["key"]);
                }
              }}
              content_id={params["key"]}
              value={params["value"]}
              defaultValue={params["defaultValue"]}
              disabled={params["disabled"]}
              placeholder={params["placeholder"]}
              variant={params["variant"]}
            />
          </div>
          <div className="form_container_label_parent">
            {UIUtils.getComponentLabel(params)}
          </div>
        </div>
      </div>
    );
  },

  getHorizontalInputWithTiltedTitleAndInfo: function(params) {
    return (
      <div className="input_container_horizontal_for_title_payslip pb-0">
        <div className="input_container_horizontal_input_parent_title_invite">
          <div className="label_input_with_icon_container_input_with_invite_page">
            <TextField
              className="form-control form-control-lg payslip_new_input_focus"
              error={params["isError"]}
              margin="normal"
              fullWidth
              id={params["key"]}
              value={params["value"]}
              onChange={(event) => {
                if (params.changeCallback) {
                  params.changeCallback(params["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (params.blurCallback) {
                  params.blurCallback(params["key"]);
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={params["disabled"]}
              select={params["select"]}
              label={params["label"]}
              SelectProps={params["selectProps"]}
              helperText={params["helperText"]}
              variant={params["variant"]}
              placeholder={params["placeholder"]}
            />
          </div>
          <div className="label_input_with_icon_container_icon_for_title_invite_page">
            <LSInfoIcon info={<IntlMessages id={params.infolabel} />} />
          </div>
        </div>
      </div>
    );
  },

  getHorizontalLabelInputWithInfo: function(params) {
    return (
      <div className="input_container_horizontal pb-0">
        <div className="input_container_horizontal_input_parent">
          <div className="label_input_with_icon_container_input">
            <TextField
              error={params["isError"]}
              margin="normal"
              fullWidth
              id={params["key"]}
              value={params["value"]}
              onChange={(event) => {
                if (params.changeCallback) {
                  params.changeCallback(params["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (params.blurCallback) {
                  params.blurCallback(params["key"]);
                }
              }}
              disabled={params["disabled"]}
              select={params["select"]}
              label={params["label"]}
              SelectProps={params["selectProps"]}
              helperText={params["helperText"]}
              variant={params["variant"]}
              placeholder={params["placeholder"]}
            />
          </div>
          <div className="label_input_with_icon_container_icon">
            <div className="info_round_icon ls_table_header_info_icon hand" />
          </div>
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelPayslipInputWithInfo: function(params) {
    return (
      <div className="input_container_horizontal_for_title_payslip pb-0">
        <div className="input_container_horizontal_input_parent_title_payslip">
          <div className="label_input_with_icon_container_input_title_payslip">
            <TextareaAutosize
              className="form-control form-control-lg payslip_new_input_focus"
              style={{ resize: "none" }}
              error={params["isError"]}
              margin="normal"
              fullWidth
              aria-label="minimum height"
              id={params["key"]}
              value={params["value"]}
              minRows={params["minRows"]}
              maxLength={160}
              onChange={(event) => {
                if (params.changeCallback) {
                  params.changeCallback(params["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (params.blurCallback) {
                  params.blurCallback(params["key"]);
                }
              }}
              disabled={params["disabled"]}
              select={params["select"]}
              label={params["label"]}
              SelectProps={params["selectProps"]}
              helperText={params["helperText"]}
              variant={params["variant"]}
              placeholder={params["placeholder"]}
            />
          </div>
          <div className="label_input_with_icon_container_icon_for_title_payslip">
            <LSInfoIcon
              info={<IntlMessages id="DIC_MAIN_PAGE_TITLE_INFO_NEW_PAY_SLIP" />}
            />
          </div>
        </div>
        <div className="input_container_horizontal_label_parent_title_payslip_title">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },
  getHorizontalExapandableLabelInputWithInfoAndTextCount: function(props) {
    return <CharacterCounterTextarea params={props} />;
  },

  getHorizontalExapandableLabelInputWithTextCountWithoutInfoAndLabel: function(
    props
  ) {
    return <CharacterCounterTextareaWithoutInfoAndLabel params={props} />;
  },

  getHorizontalExapandableLabelInputWithInfo: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <div className="label_input_with_icon_container_input">
            <TextareaAutosize
              className="form-control form-control-lg payslip_new_input_focus"
              error={params["isError"]}
              margin="normal"
              fullWidth
              aria-label="minimum height"
              id={params["key"]}
              value={params["value"]}
              minRows={params["minRows"]}
              maxLength={160}
              onChange={(event) => {
                if (params.changeCallback) {
                  params.changeCallback(params["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (params.blurCallback) {
                  params.blurCallback(params["key"]);
                }
              }}
              disabled={params["disabled"]}
              select={params["select"]}
              label={params["label"]}
              SelectProps={params["selectProps"]}
              helperText={params["helperText"]}
              variant={params["variant"]}
              placeholder={params["placeholder"]}
            />
          </div>
          <div className="label_input_with_icon_container_icon">
            {/* <div className="info_round_icon ls_table_header_info_icon hand"/> */}
            <LSInfoIcon
              info={
                <IntlMessages id="DIC_MAIN_PAGE_MESSAGE_INFO_NEW_PAY_SLIP" />
              }
            />
          </div>
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalExapandableLabelInputWithInfo: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <div className="label_input_with_icon_container_input">
            <textarea
              className="form-control form-control-lg payslip_new_input_focus"
              error={params["isError"]}
              margin="normal"
              fullWidth
              id={params["key"]}
              value={params["value"]}
              onChange={(event) => {
                if (params.changeCallback) {
                  params.changeCallback(params["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (params.blurCallback) {
                  params.blurCallback(params["key"]);
                }
              }}
              disabled={params["disabled"]}
              select={params["select"]}
              label={params["label"]}
              SelectProps={params["selectProps"]}
              helperText={params["helperText"]}
              variant={params["variant"]}
              placeholder={params["placeholder"]}
            />
          </div>
          <div className="label_input_with_icon_container_icon">
            {/* <div className="info_round_icon ls_table_header_info_icon hand"/> */}
            <LSInfoIcon
              info={
                <IntlMessages id="DIC_MAIN_PAGE_DESCRIPTION_INFO_NEW_PAY_SLIP" />
              }
            />
          </div>
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelInput: function(params) {
    return (
      <div className="input_container_horizontal">
        <div className="input_container_horizontal_input_parent">
          <TextField
            error={params["isError"]}
            margin="normal"
            fullWidth
            id={params["key"]}
            value={params["value"]}
            onChange={(event) => {
              if (params.changeCallback) {
                params.changeCallback(params["key"], event.target.value);
              }
            }}
            onBlur={() => {
              if (params.blurCallback) {
                params.blurCallback(params["key"]);
              }
            }}
            disabled={params["disabled"]}
            select={params["select"]}
            label={params["label"]}
            SelectProps={params["selectProps"]}
            helperText={params["helperText"]}
            variant={params["variant"]}
            placeholder={params["placeholder"]}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getPhoneInput: function(params) {
    return (
      <div className="input_container_horizontal">
        <div
          className="input_container_horizontal_input_parent"
          style={params["style"]}
        >
          <PhoneNumber
            identifier={params["key"]}
            value={params.value || ""}
            required={params.required}
            variant="outlined"
            onChange={(phone) => params.changeCallback(params["key"], phone)}
            countryMapping={params.countryMapping || {}}
            handleInvalidPhoneNumber={params.handleInvalidPhoneNumber}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelInput: function(params) {
    return (
      <div className="input_container_horizontal">
        <div
          className="input_container_horizontal_input_parent"
          style={params["style"]}
        >
          <TextField
            error={params["isError"]}
            margin="normal"
            fullWidth
            id={params["key"]}
            value={params["value"]}
            onChange={(event) => {
              if (params.changeCallback) {
                params.changeCallback(params["key"], event.target.value);
              }
            }}
            onBlur={() => {
              if (params.blurCallback) {
                params.blurCallback(params["key"]);
              }
            }}
            disabled={params["disabled"]}
            select={params["select"]}
            label={params["label"]}
            SelectProps={params["selectProps"]}
            helperText={params["helperText"]}
            variant={params["variant"]}
            placeholder={params["placeholder"]}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelInputWithCalendarIcon: function(params) {
    return (
      <div className="input_container_horizontal">
        <div
          className="input_container_horizontal_input_parent"
          style={params["style"]}
        >
          <TextField
            error={params["isError"]}
            margin="normal"
            fullWidth
            id={params["key"]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className="survey_invite_calendar_icon_for_period_input"></div>
                </InputAdornment>
              ),
            }}
            value={params["value"]}
            onChange={(event) => {
              if (params.changeCallback) {
                params.changeCallback(params["key"], event.target.value);
              }
            }}
            onBlur={() => {
              if (params.blurCallback) {
                params.blurCallback(params["key"]);
              }
            }}
            disabled={params["disabled"]}
            select={params["select"]}
            label={params["label"]}
            SelectProps={params["selectProps"]}
            helperText={params["helperText"]}
            variant={params["variant"]}
            placeholder={params["placeholder"]}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getHorizontalLabelInputWithDropdownIconWithoutLabel: function(params) {
    return (
      <div>
        <div>
          <TextField
            error={params["isError"]}
            margin="normal"
            fullWidth
            id={params["key"]}
            value={params["value"]}
            onChange={(event) => {
              if (params.changeCallback) {
                params.changeCallback(params["key"], event.target.value);
              }
            }}
            onBlur={() => {
              if (params.blurCallback) {
                params.blurCallback(params["key"]);
              }
            }}
            InputProps={{
              style: { paddingLeft: "12px", borderRadius: "0px 0px 4px 4px" },
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownIcon padding="0px" />
                </InputAdornment>
              ),
            }}
            disabled={params["disabled"]}
            select={params["select"]}
            label={params["label"]}
            SelectProps={params["selectProps"]}
            helperText={params["helperText"]}
            variant={params["variant"]}
            placeholder={params["placeholder"]}
            style={params["style"]}
          />
        </div>
      </div>
    );
  },

  getHorizontalChipsLayout: function(params) {
    return (
      <div className="input_container_horizontal_auto">
        <div className="input_container_horizontal_input_parent">
          <MultiSelectChipSelect
            id={params["key"]}
            items={params["items"]}
            selectedItems={params["value"]}
            placeholder={params["placeholder"]}
            callback={params.changeCallback}
            disabled={params["disabled"]}
            variant={params["variant"]}
          />
        </div>
        <div className="input_container_horizontal_label_parent">
          {UIUtils.getComponentLabel(params)}
        </div>
      </div>
    );
  },

  getNonLabelChipsLayout: function(params) {
    return (
      <div className="input_container_horizontal_auto">
        <div className="input_container_horizontal_input_parent">
          <MultiSelectChipSelect
            id={params["key"]}
            items={params["items"]}
            selectedItems={params["value"]}
            placeholder={<IntlMessages id={params["localisation"]} />}
            callback={params.changeCallback}
            disabled={params["disabled"]}
          />
        </div>
      </div>
    );
  },

  getComponentLabel: function(params) {
    return typeof params["localisation"] != "undefined" &&
      params["localisation"] !== "" ? (
      <span className="form_container_label_content">
        {params["title"] ? (
          params["title"]
        ) : (
          <label
            className={
              typeof params["labelClass"] != "undefined"
                ? params["labelClass"]
                : ""
            }
          >
            <IntlMessages id={params["localisation"]} />
          </label>
        )}
        {params["isStar"] ? (
          <label className="text-danger" style={params["star_style"]}>
            <sup>{"*"}</sup>
          </label>
        ) : null}
        {params["subLabel"] && (
          <label
            className={
              params["subLabel"]["className"]
                ? params["subLabel"]["className"]
                : ""
            }
            onClick={() => {
              if (params["changeCallback"]) {
                params["changeCallback"](params["subLabel"]["key"]);
              }
            }}
          >
            <IntlMessages id={params["subLabel"]["localisation"]} />
          </label>
        )}
      </span>
    ) : typeof params["title"] != "undefined" && params["title"] !== "" ? (
      <span className="form_container_label_content">
        <span>{params["title"]}</span>
        {params["isStar"] ? (
          <label className="text-danger" style={params["star_style"]}>
            <sup>{"*"}</sup>
          </label>
        ) : null}

        {params["subLabel"] && (
          <label
            className={
              params["subLabel"]["className"]
                ? params["subLabel"]["className"]
                : ""
            }
            onClick={() => {
              if (params["changeCallback"]) {
                params["changeCallback"](params["subLabel"]["key"]);
              }
            }}
          >
            <IntlMessages id={params["subLabel"]["localisation"]} />
          </label>
        )}
      </span>
    ) : null;
  },

  getLSThemeComponentLabel: function(params) {
    return typeof params["localisation"] != "undefined" &&
      params["localisation"] !== "" ? (
      <span className="form_container_label_content">
        {params["title"] ? (
          params["title"]
        ) : (
          <label
            className={
              typeof params["labelClass"] != "undefined"
                ? params["labelClass"]
                : ""
            }
          >
            <IntlMessages id={params["localisation"]} />
          </label>
        )}
        {params["isStar"] ? (
          <label className="text-danger" style={params["star_style"]}>
            <sup>{"*"}</sup>
          </label>
        ) : null}

        {params["subLabel"] && (
          <label
            className={
              params["subLabel"]["className"]
                ? params["subLabel"]["className"]
                : ""
            }
            onClick={() => {
              if (params["changeCallback"]) {
                params["changeCallback"](params["subLabel"]["key"]);
              }
            }}
          >
            <IntlMessages id={params["subLabel"]["localisation"]} />
          </label>
        )}
      </span>
    ) : null;
  },

  getLSThemeComponentLabelBold: function(params) {
    return typeof params["localisation"] != "undefined" &&
      params["localisation"] !== "" ? (
      <span className="form_container_label_content_bold">
        {params["title"] ? (
          params["title"]
        ) : (
          <label
            className={
              typeof params["labelClass"] != "undefined"
                ? params["labelClass"]
                : ""
            }
          >
            <IntlMessages id={params["localisation"]} />
          </label>
        )}
        {params["isStar"] ? (
          <label className="text-danger" style={params["star_style"]}>
            <sup>{"*"}</sup>
          </label>
        ) : null}

        {params["subLabel"] && (
          <label
            className={
              params["subLabel"]["className"]
                ? params["subLabel"]["className"]
                : ""
            }
            onClick={() => {
              if (params["changeCallback"]) {
                params["changeCallback"](params["subLabel"]["key"]);
              }
            }}
          >
            <IntlMessages id={params["subLabel"]["localisation"]} />
          </label>
        )}
      </span>
    ) : params["title"] ? (
      params["title"]
    ) : null;
  },

  getMultiSelectDropDownWithChip: function(props) {
    return <MultiSelectChipDropdown params={props} />;
  },

  getDateTimePopUpWithSelectTimeAndImmediate: function(props) {
    return <DateTimeWithPopUp params={props} />;
  },

  getSurveyGrayScaleEmojiSvgContent: function(iconClass) {
    let result = "";
    try {
      switch (iconClass) {
        case SURVEY_QUESTION_EMOJI_TYPES.VERY_POSITIVE: {
          result = (
            <>
              <g clip-path="url(#clip0_1866_39894)">
                <circle cx="9.875" cy="10.625" r="1.875" fill="#4F4F4F" />
                <circle cx="17.375" cy="10.625" r="1.875" fill="#4F4F4F" />
                <path
                  d="M9.61251 16.2023L8.53364 16.0061C7.68116 15.8511 7.13793 16.8879 7.7506 17.5006C11.202 20.952 16.798 20.952 20.2494 17.5006C20.8621 16.8879 20.3188 15.8511 19.4664 16.0061L18.3875 16.2023C15.4863 16.7298 12.5137 16.7298 9.61251 16.2023Z"
                  fill="#4F4F4F"
                />
                <rect
                  x="1"
                  y="1"
                  width="26"
                  height="26"
                  rx="13"
                  stroke="#4F4F4F"
                  strokeWidth="2"
                />
              </g>
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke="#4F4F4F"
                strokeWidth="2"
              />
              <defs>
                <clipPath id="clip0_1866_39894">
                  <rect
                    x="2"
                    y="2"
                    width="24"
                    height="24"
                    rx="12"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </>
          );
          break;
        }

        case SURVEY_QUESTION_EMOJI_TYPES.POSITIVE: {
          result = (
            <>
              <circle cx="9.875" cy="10.625" r="1.875" fill="#4F4F4F" />
              <circle cx="17.375" cy="10.625" r="1.875" fill="#4F4F4F" />
              <path
                d="M9.14392 16.0809C8.63629 15.8633 8.04841 16.0985 7.83085 16.6061C7.6133 17.1137 7.84845 17.7016 8.35608 17.9191L9.14392 16.0809ZM19.6439 17.9191C20.1515 17.7016 20.3867 17.1137 20.1691 16.6061C19.9516 16.0985 19.3637 15.8633 18.8561 16.0809L19.6439 17.9191ZM8.35608 17.9191C11.9602 19.4638 16.0398 19.4638 19.6439 17.9191L18.8561 16.0809C15.7551 17.4099 12.2449 17.4099 9.14392 16.0809L8.35608 17.9191Z"
                fill="#4F4F4F"
              />
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke="#4F4F4F"
                strokeWidth="2"
              />
            </>
          );
          break;
        }

        case SURVEY_QUESTION_EMOJI_TYPES.NEUTRAL: {
          result = (
            <>
              <circle cx="10.625" cy="10.625" r="1.875" fill="#4F4F4F" />
              <circle cx="18.125" cy="10.625" r="1.875" fill="#4F4F4F" />
              <path
                d="M9.5 16.75C8.94772 16.75 8.5 17.1977 8.5 17.75C8.5 18.3023 8.94772 18.75 9.5 18.75V16.75ZM19.25 18.75C19.8023 18.75 20.25 18.3023 20.25 17.75C20.25 17.1977 19.8023 16.75 19.25 16.75V18.75ZM9.5 18.75H14.75V16.75H9.5V18.75ZM14.75 18.75H19.25V16.75H14.75V18.75Z"
                fill="#4F4F4F"
              />
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke="#4F4F4F"
                strokeWidth="2"
              />
            </>
          );
          break;
        }

        case SURVEY_QUESTION_EMOJI_TYPES.NEGATIVE: {
          result = (
            <>
              <circle cx="10.625" cy="10.625" r="1.875" fill="#4F4F4F" />
              <circle cx="18.125" cy="10.625" r="1.875" fill="#4F4F4F" />
              <path
                d="M9.10608 18.3309C8.59845 18.5484 8.3633 19.1363 8.58085 19.6439C8.79841 20.1515 9.38629 20.3867 9.89392 20.1691L9.10608 18.3309ZM10.1256 18.9819L9.73172 18.0627L10.1256 18.9819ZM18.8028 20.1444C19.2968 20.3914 19.8974 20.1912 20.1444 19.6972C20.3914 19.2032 20.1912 18.6026 19.6972 18.3556L18.8028 20.1444ZM9.89392 20.1691L10.5196 19.901L9.73172 18.0627L9.10608 18.3309L9.89392 20.1691ZM10.5196 19.901C13.1821 18.7599 16.2119 18.849 18.8028 20.1444L19.6972 18.3556C16.5801 16.797 12.9349 16.6899 9.73172 18.0627L10.5196 19.901Z"
                fill="#4F4F4F"
              />
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke="#4F4F4F"
                strokeWidth="2"
              />
            </>
          );
          break;
        }

        case SURVEY_QUESTION_EMOJI_TYPES.VERY_NEGATIVE: {
          result = (
            <>
              <circle cx="10.625" cy="10.625" r="1.875" fill="#4F4F4F" />
              <circle cx="18.125" cy="10.625" r="1.875" fill="#4F4F4F" />
              <path
                d="M9.61251 19.2977L8.53364 19.4939C7.68116 19.6489 7.13793 18.6121 7.7506 17.9994C11.202 14.548 16.798 14.548 20.2494 17.9994C20.8621 18.6121 20.3188 19.6489 19.4664 19.4939L18.3875 19.2977C15.4863 18.7702 12.5137 18.7702 9.61251 19.2977Z"
                fill="#4F4F4F"
              />
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke="#4F4F4F"
                strokeWidth="2"
              />
            </>
          );
          break;
        }

        case SURVEY_QUESTION_EMOJI_TYPES.SKIP: {
          result = (
            <>
              <path
                d="M9.5 16.75C8.94772 16.75 8.5 17.1977 8.5 17.75C8.5 18.3023 8.94772 18.75 9.5 18.75V16.75ZM19.25 18.75C19.8023 18.75 20.25 18.3023 20.25 17.75C20.25 17.1977 19.8023 16.75 19.25 16.75V18.75ZM9.5 18.75H14.75V16.75H9.5V18.75ZM14.75 18.75H19.25V16.75H14.75V18.75Z"
                fill="#4F4F4F"
              />
              <path
                d="M9.5 10C8.94772 10 8.5 10.4477 8.5 11C8.5 11.5523 8.94772 12 9.5 12V10ZM12.5 12C13.0523 12 13.5 11.5523 13.5 11C13.5 10.4477 13.0523 10 12.5 10V12ZM9.5 12H12.5V10H9.5V12Z"
                fill="#4F4F4F"
              />
              <path
                d="M16.25 10C15.6977 10 15.25 10.4477 15.25 11C15.25 11.5523 15.6977 12 16.25 12V10ZM19.25 12C19.8023 12 20.25 11.5523 20.25 11C20.25 10.4477 19.8023 10 19.25 10V12ZM16.25 12H19.25V10H16.25V12Z"
                fill="#4F4F4F"
              />
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke="#4F4F4F"
                strokeWidth="2"
              />
            </>
          );
          break;
        }
      }
    } catch (ex) {
      console.error("getSurveyGrayScaleEmojiSvgContent : error : ", ex);
    }

    return result;
  },

  getDownloadButton: function(params, iconClass) {
    return (
      <Button
        disabled={params["disabled"]}
        variant="contained"
        className={"active_download_button_with_icon"}
        style={params["style"]}
        onClick={() => {
          if (params.onClick) {
            params.onClick(params["key"]);
          }
        }}
      >
        <span
          className={
            "p-1 " +
            (params["disabled"]
              ? "button_text_color_disabled"
              : "button_text_color_primary")
          }
        >
          {<IntlMessages id={params["label"]} />}
        </span>
        <span className={iconClass} />
      </Button>
    );
  },

  getButtonByStyleClass: function(params) {
    return (
      <Button
        disabled={params["disabled"]}
        variant={params["variant"]}
        className={params["button_class"]}
        style={params["style"]}
        onClick={() => {
          if (params.onClick) {
            params.onClick(params["key"]);
          }
        }}
      >
        <span className={params["button_text_class"]}>
          <IntlMessages id={params["label_id"]} />
        </span>
      </Button>
    );
  },

  getButtonDropdownMenu: function(params) {
    return (
      <>
        <Button
          disabled={params["disabled"]}
          variant={params["variant"]}
          className={params["button_class"]}
          style={params["style"]}
          onClick={(e) => {
            if (params.onClick) {
              params.onClick(params["key"], undefined, e);
            }
          }}
        >
          <span className={params["iconClass"]} />
          <span className={`p-1 ${params["button_text_class"]}`}>
            {params["label_id"] ? (
              <IntlMessages id={params["label_id"]} />
            ) : params["label"] ? (
              params["label"]
            ) : (
              ""
            )}
          </span>
          <span
            className={
              params["menuState"] === true
                ? "wc_import_menu_indicator_active float-right"
                : "wc_import_menu_indicator float-right"
            }
          ></span>
        </Button>
        <Menu
          id={params["key"]}
          anchorEl={params["anchorEl"]}
          open={Boolean(params["menuState"])}
          onClose={params.handleRequestClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {params["menuOptions"].map((option) => {
            return (
              <MenuItem
                key={option["key"]}
                onClick={(e) => {
                  params.handleRequestClose();
                  params.onClick(option["key"], undefined, e);
                }}
              >
                <span className="wc_import_menu_item_text">
                  <IntlMessages id={option["label_id"]} />
                </span>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  },
};

export default UIUtils;
